import axios from "axios";
import jwt from "jsonwebtoken";
import {
  postRequest,
  deleteRequest,
  putRequest,
  getRequest,
} from "./serverCall";

import { URL } from "./services.js";

var PUBLIC_KEY = ``;

// Get public key from localStorage or fetch from server
const getPublicKey = async () => {
  const storedKey = localStorage.getItem('rsa_public_key');
  if (storedKey) {
    PUBLIC_KEY = storedKey;
    return storedKey;
  }
  
  try {
    const response = await axios(URL + "/system/public-rsa-key");
    PUBLIC_KEY = response.data;
    localStorage.setItem('rsa_public_key', response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch public key:', error);
    return null;
  }
};

// Initialize public key on load
getPublicKey();

const USER_SIGN_UP_ENDPOINT = "/user/signup";
const UPDATE_MANAGER_USER_ENDPOINT = "/user/put/manager";
const UPDATE_DIRECTOR_USER_ENDPOINT = "/user/put/director";
const UPDATE_USER_PASSWORD_ENDPOINT = "/user/put/password";
const DELETE_MANAGER_USER_ENDPOINT = "/user/delete/manager";
const DELETE_DIRECTOR_USER_ENDPOINT = "/user/delete/director";
const USER_LOGIN_ENDPOINT = "/user/authenticate";
const MANAGER_TO_ESTABLISHMENT_ENDPOINT = "/manager_to_establishment";
const REFRESH_TOKEN_ENDPOINT = "/user/refresh_token";

// LOGIN
export async function login(userPayload, keepLogin) {
  /* Send Login request and return the decoded token */
  let result = await postRequest(USER_LOGIN_ENDPOINT, userPayload);
  if (result?.success) {
    let token = result.data?.["accessToken"];
    saveLogin(keepLogin, token);
    return getDecodedToken(token);
  }
  return result;
}

export function saveLogin(keepLogin, token) {
  /* Decides if the user wants to keep logged in */
  if (keepLogin) {
    /* Keeps a copy of the token on the local storage */
    saveTokenOnLocalStorage(token);
    setTokenAsDefaultHeader(token);
  } else {
    /* Removes header authorization on refresh */
    setTokenAsDefaultHeader(token);
  }
}
// LOGOUT
export function logout() {
  removeTokenFromLocalStorage();
  removeTokenAuthorizationHeader();
}
// SAVES

export function saveTokenOnLocalStorage(token) {
  /* Save token on local Storage */
  if (!token) {
    throw new Error("No token was provided");
  } else {
    localStorage.setItem("token", JSON.stringify(token));
  }
}

// SET
export function setTokenAsDefaultHeader(token) {
  /* Set token as default authorization header */
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    throw new Error("No token was provided");
  }
}

export function setStoredTokenAsDefaultHeader() {
  /* Se busca el token en el local Storage y se establece como authentication header */
  let token = getTokenFromLocalStorage();
  setTokenAsDefaultHeader(token);
}

// REMOVE
export function removeTokenAuthorizationHeader() {
  /* Removes any default authorization header */
  delete axios.defaults.headers.common["Authorization"];
}
export function removeTokenFromLocalStorage() {
  /* Try to remove any existing token from local storage */
  try {
    localStorage.removeItem("token");
  } catch (e) {
    throw new Error("No token found on local storage");
  }
}

// DECODE

export function getDecodedToken(token) {
  /* return token information if a valid token is provided */
  try {
    const tokenDecoded = jwt.verify(token, PUBLIC_KEY);
    return { success: true, token: tokenDecoded };
  } catch (error) {
    let result = { success: false, data: error.message };
    return result;
  }
}

export function getDecodedTokenFromAuthHeader() {
  // get token from authentication header and return the decoded token
  let token = axios.defaults.headers.common?.["Authorization"];
  if (token) {
    token = token.split(" ")[1];
    return getDecodedToken(token);
  }
}
export function getDecodedTokenFromLocalStorage() {
  // get token from local storage and return the decoded token
  let token = getTokenFromLocalStorage();
  return getDecodedToken(token);
}

export function getTokenFromLocalStorage() {
  /* return a token if can find any in the local storage */
  try {
    let token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      return token;
    }
    return null;
  } catch (e) {
    throw new Error("No token found");
  }
}

// REFRESH

export async function refreshToken() {
  let result = await getRequest(REFRESH_TOKEN_ENDPOINT);
  let token = result?.data?.accessToken;
  if (result.success && token) {
    setTokenAsDefaultHeader(token);
    if (getTokenFromLocalStorage()) {
      saveTokenOnLocalStorage(token);
    }
    return { success: true };
  } else {
    return {
      success: false,
      data: {
        type: "alert",
        text: "No se puedo refrscar correctamnet el token, porfavor cierra sesión y vuelve a entrar",
      },
    };
  }
}

// VALIDATORS

export function hasActiveAutTokenHeader() {
  if (axios.defaults.headers.common["Authorization"]) {
    return true;
  }
  return false;
}

export function hasToLogin() {
  let hasActiveLogin = hasValidTokenOnLocalStorage();
  let hasActiveAutHeader = hasActiveAutTokenHeader();

  if (hasActiveAutHeader) {
    return false;
  }
  if (hasActiveLogin) {
    setStoredTokenAsDefaultHeader();
    return false;
  }
  return true;
}

export function hasValidTokenOnLocalStorage() {
  let token = getTokenFromLocalStorage();
  if (token) {
    /* Test if token is valid */
    let result = getDecodedToken(token);
    if (result.success) {
      /* Valid Token, return true*/
      return true;
    } else {
      /* Invalid Token, remove from local strorage and header, return false */
      logout();
      return false;
    }
  }
  /* No token, return false */
  return false;
}

// POST USERS
export async function postManagerUser(data) {
  let result = await postRequest(USER_SIGN_UP_ENDPOINT, data);
  return result;
}

export async function postDirectorUser(data) {
  let result = await postRequest(USER_SIGN_UP_ENDPOINT, data);
  return result;
}

// PUT USERS
export async function putManagerUser(data) {
  let endpoint = UPDATE_MANAGER_USER_ENDPOINT + "/" + data.id;
  let result = await putRequest(endpoint, { username: data.username });
  return result;
}

export async function putDirectorUser(data) {
  let endpoint = UPDATE_DIRECTOR_USER_ENDPOINT + "/" + data.id;
  let result = await putRequest(endpoint, { username: data.username });
  return result;
}
export async function putUserPassword(userId, password) {
  let endpoint = UPDATE_USER_PASSWORD_ENDPOINT + "/" + userId;
  let result = await putRequest(endpoint, { password: password });
  return result;
}
// DELETE USERS
export async function deleteManagerUser(id) {
  let endpoint = DELETE_MANAGER_USER_ENDPOINT + "/" + id;
  let result = await deleteRequest(endpoint);
  return result;
}

export async function deleteDirectorUser(id) {
  let endpoint = DELETE_DIRECTOR_USER_ENDPOINT + "/" + id;
  let result = await deleteRequest(endpoint);
  return result;
}

// POST MANAGER RELATIONS
export async function postManagerRelations(managerId, establishmentIds) {
  let responseArray = [];
  if (establishmentIds?.length > 0) {
    for (const id of establishmentIds) {
      responseArray.push(
        postRequest(MANAGER_TO_ESTABLISHMENT_ENDPOINT, {
          managerId: managerId,
          establishmentId: id,
        })
      );
    }
    let resultArray = await Promise.all(responseArray);
    let result = {
      success: resultArray.every((el) => el.success == true),
      data: resultArray,
    };
    return result;
  } else {
    return {
      success: true,
      data: [],
    };
  }
}

// DELETE MANAGER RELATIONS
export async function deleteManagerEstablishmentRelations(
  releatedEstablishmentsIdsToRemove
) {
  console.log(releatedEstablishmentsIdsToRemove, "on function");
  let responseArray = [];
  for (const id of releatedEstablishmentsIdsToRemove) {
    let arg = `/${id}`;
    responseArray.push(deleteRequest(MANAGER_TO_ESTABLISHMENT_ENDPOINT + arg));
  }
  let resultArray = await Promise.all(responseArray);
  let result = {
    success: resultArray.every((el) => el.success == true),
    data: resultArray,
  };
  return result;
}

<template>
  <div class="main-holder">
    <v-row class="ml-2">
      <v-col>
        <div class="d-flex flex-row align-center py-2">
          <h4 class="mx-2 grey--text font-weight-regular">Planeadas</h4>
          <v-chip small class="" color="info">
            <span class="" style="font-weight: 600; font-size: 15px">
              {{ totalPlanned }}
            </span>
          </v-chip>
          <v-icon v-if="totalPlanned != rentQqHa" color="warning">
            mdi-alert-decagram-outline
          </v-icon>
        </div>
        <div class="main-holder">
          <v-form ref="form2" class="">
            <div class="d-flex">
              <v-text-field
                label="Quintales Planeados"
                v-model.number="newPlannedInstallment.qqHa"
                :rules="required"
                type="number"
                outlined
                dense
                class="managrx-input mr-2"
              ></v-text-field>
              <v-text-field
                label="Fecha"
                :rules="required"
                class="managrx-input mr-2"
                outlined
                dense
                v-model="newPlannedInstallment.date"
                type="date"
              ></v-text-field>
              <v-btn
                elevation="0"
                color="primary"
                @click="saveNewPlannedInstallment"
                :depressed="false"
                class="ml-2 mr-2"
              >
                <v-icon> mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-for="installment in rentPaymentPlan" :key="installment.id">
          <div class="main-holder">
            <div
              class="d-flex"
              v-if="
                editModePlanned && installment.id == plannedInstallmentEdit.id
              "
            >
              <v-text-field
                label="Quintales Planeados"
                v-model.number="plannedInstallmentEdit.qqHa"
                class="managrx-input mr-2"
                type="number"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                label="Fecha"
                class="managrx-input mr-2"
                v-model="plannedInstallmentEdit.date"
                outlined
                dense
                type="date"
              ></v-text-field>

              <v-btn
                elevation="0"
                @click="saveEditPlannedInstallment"
                color="primary"
                :depressed="false"
                class="ml-2 mr-2"
              >
                <v-icon> mdi-floppy</v-icon>
              </v-btn>
              <v-btn
                elevation="0"
                color="info white--text"
                :depressed="false"
                @click="exitEditPlannedInstallment"
              >
                Atrás
              </v-btn>
            </div>
            <div class="d-flex" v-else>
              <v-text-field
                label="Quintales Planeados"
                :value="installment.qqHa"
                class="managrx-input mr-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
              <v-text-field
                label="Fecha"
                class="managrx-input mr-2"
                :value="installment.date.slice(0, 10)"
                outlined
                readonly
                dense
                type="date"
                filled
              ></v-text-field>

              <v-btn
                elevation="0"
                color="error white--text lighten-1"
                :depressed="false"
                v-if="
                  deleteModePlanned &&
                  installment.id == plannedInstallmentDelete.id
                "
                @click="confirmDeletePlannedInstallment"
              >
                Borrar
              </v-btn>
              <v-btn
                elevation="0"
                color="warning"
                :depressed="false"
                @click="editPlannedInstallmentClick(installment)"
                class="mr-2"
                v-else
              >
                <v-icon> mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                elevation="0"
                color="warning white--text"
                :depressed="false"
                @click="exitDeletePlannedInstallment"
                v-if="
                  deleteModePlanned &&
                  installment.id == plannedInstallmentDelete.id
                "
                class="ml-2"
              >
                Atrás
              </v-btn>

              <v-btn
                elevation="0"
                color="error white--text"
                :depressed="false"
                v-else
                @click="deletePlannedInstallmentClick(installment)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex flex-row align-center py-2">
          <h4 class="mx-2 grey--text font-weight-regular">Fijaciones</h4>
          <v-chip small class="" color="info">
            <span class="" style="font-weight: 600; font-size: 15px">
              {{ totalFixed }}
            </span>
          </v-chip>
        </div>
        <div class="main-holder">
          <v-form ref="form1" class="">
            <div class="d-flex">
              <v-text-field
                label="Toneladas Totales Fijadas"
                v-model.number="newFixedInstallment.fixedTon"
                type="number"
                :rules="required"
                outlined
                dense
                class="managrx-input mr-2"
              ></v-text-field>
              <v-text-field
                label="Precio de Fijación por Tn."
                :rules="required"
                v-model.number="newFixedInstallment.priceTon"
                type="number"
                outlined
                dense
                class="managrx-input mr-2"
              ></v-text-field>
              <v-text-field
                label="Fecha de Fijación"
                :rules="required"
                class="managrx-input mr-2"
                outlined
                dense
                v-model="newFixedInstallment.date"
                type="date"
              ></v-text-field>

              <v-btn
                elevation="0"
                color="primary"
                @click="saveNewFixedInstallment"
                :depressed="false"
                class="ml-2 mr-2"
              >
                <v-icon> mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-for="installment in rentFixedRows" :key="installment.id">
          <div class="main-holder">
            <div
              class="d-flex"
              v-if="editModeFixed && installment.id == fixedInstallmentEdit.id"
            >
              <v-text-field
                label="Toneladas Totales Fijadas"
                v-model="fixedInstallmentEdit.fixedTon"
                class="managrx-input mr-2"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                label="Precio de Fijación por Tn."
                v-model.number="fixedInstallmentEdit.priceTon"
                type="number"
                outlined
                dense
                class="managrx-input mr-2"
              ></v-text-field>
              <v-text-field
                label="Fecha de Fijación"
                class="managrx-input mr-2"
                v-model="fixedInstallmentEdit.date"
                outlined
                dense
                type="date"
              ></v-text-field>

              <v-btn
                elevation="0"
                @click="saveEditFixedInstallment"
                color="primary"
                :depressed="false"
                class="ml-2 mr-2"
              >
                <v-icon> mdi-floppy</v-icon>
              </v-btn>
              <v-btn
                elevation="0"
                color="info white--text"
                :depressed="false"
                @click="exitEditFixedInstallment"
              >
                Atrás
              </v-btn>
            </div>
            <div class="d-flex" v-else>
              <v-text-field
                label="Toneladas Totales Fijadas"
                :value="installment.fixedTon"
                class="managrx-input mr-2"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
              <v-text-field
                label="Precio de Fijación por Tn."
                :value="installment.priceTon"
                type="number"
                outlined
                readonly
                filled
                dense
                class="managrx-input mr-2"
              ></v-text-field>
              <v-text-field
                label="Fecha de Fijación"
                class="managrx-input mr-2"
                :value="installment.date.slice(0, 10)"
                outlined
                readonly
                dense
                type="date"
                filled
              ></v-text-field>

              <v-btn
                elevation="0"
                color="error white--text lighten-1"
                @click="confirmDeleteFixedInstallment"
                :depressed="false"
                v-if="
                  deleteModeFixed && fixedInstallmentDelete.id == installment.id
                "
              >
                Borrar
              </v-btn>
              <v-btn
                elevation="0"
                color="warning"
                :depressed="false"
                @click="editFixedInstallmentClick(installment)"
                class="mr-2"
                v-else
              >
                <v-icon> mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                elevation="0"
                color="warning white--text"
                :depressed="false"
                @click="exitDeleteFixedInstallment"
                v-if="
                  deleteModeFixed && fixedInstallmentDelete.id == installment.id
                "
                class="ml-2"
              >
                Atrás
              </v-btn>
              <v-btn
                elevation="0"
                @click="deleteFixedInstallmentClick(installment)"
                color="error white--text"
                :depressed="false"
                v-else
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="ml-2">
      <v-col>
        <div class="d-flex flex-row align-center py-2">
          <h4 class="mx-2 grey--text font-weight-regular">Bonificaciones</h4>
          <v-btn
            :disabled="draftEngagementActivitySlices.length == 0"
            @click="addKgBonuses"
            x-small
            class="me-2"
            color="info"
            v-if="bonuses.length == 0"
            >Agregar Kg / Ha</v-btn
          >
          <v-btn
            :disabled="draftEngagementActivitySlices.length == 0"
            @click="addUsdBonuses"
            x-small
            color="info"
            v-if="bonuses.length == 0"
            >Agregar USD / Ha</v-btn
          >
        </div>
        <div
          v-if="bonuses.length == 0"
          class="d-flex flex-row justify-center my-4"
        >
          <span
            class="text-overline grey--text text-subtitle-2"
            style="font-size: 18px !important"
          >
            Sin bonificaciones
          </span>
        </div>
        <div v-for="bonus in bonuses" :key="bonus.id" class="main-holder">
          <v-form ref="form4" class="">
            <div class="d-flex">
              <v-select
                :items="[
                  { name: 'Kg / Ha', value: false },
                  { name: 'USD / Ha', value: true },
                ]"
                v-model="bonus.isUsdHa"
                filled
                readonly
                item-text="name"
                item-value="value"
                select
                outlined
                dense
                label="Tipo de Bonificación"
                class="managrx-input mr-2"
              >
              </v-select>
              <v-select
                :items="activities"
                v-model="bonus.activityId"
                filled
                readonly
                item-text="name"
                item-value="id"
                select
                outlined
                dense
                label="Actividad"
                class="managrx-input mr-2"
              >
              </v-select>
              <v-text-field
                label="Rinde Bonificado"
                v-model.number="bonus.yieldThreshold"
                :rules="required"
                type="number"
                outlined
                dense
                :filled="!editBonusMode || bonus.id != bonusEditId"
                :readonly="!editBonusMode || bonus.id != bonusEditId"
                class="managrx-input mr-2"
              ></v-text-field>
              <v-text-field
                label="(0-100)% Bonificado"
                v-model.number="bonus.rentBonusPercent"
                :rules="required"
                type="number"
                outlined
                :filled="!editBonusMode || bonus.id != bonusEditId"
                :readonly="!editBonusMode || bonus.id != bonusEditId"
                dense
                class="managrx-input mr-2"
              ></v-text-field>
              <v-text-field
                label="Fecha de bonificación"
                :rules="required"
                class="managrx-input mr-2"
                outlined
                dense
                :filled="!editBonusMode || bonus.id != bonusEditId"
                :readonly="!editBonusMode || bonus.id != bonusEditId"
                v-model="bonus.bonusDate"
                type="date"
              ></v-text-field>
              <v-btn
                elevation="0"
                v-if="bonus.id != bonusDeleteId && bonus.id != bonusEditId"
                color="warning"
                @click="editBonusClick(bonus.id)"
                :depressed="false"
                class="ml-2 mr-2"
              >
                <v-icon> mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                elevation="0"
                v-if="editBonusMode && bonus.id == bonusEditId"
                color="primary"
                @click="saveEditBonus(bonus)"
                :depressed="false"
                class="ml-2 mr-2"
              >
                <v-icon> mdi-floppy</v-icon>
              </v-btn>

              <v-btn
                elevation="0"
                v-if="editBonusMode && bonus.id == bonusEditId"
                color="info white--text"
                @click="cancelBonusEdit"
                :depressed="false"
                class="ml-2 mr-2"
              >
                Atrás
              </v-btn>
              <v-btn
                elevation="0"
                v-if="bonus.id != bonusDeleteId && bonus.id != bonusEditId"
                color="error white--text"
                @click="deleteBonusClick(bonus.id)"
                :depressed="false"
                class="ml-2 mr-2"
              >
                <v-icon> mdi-trash-can</v-icon>
              </v-btn>
              <v-btn
                elevation="0"
                v-if="deleteModeBonus && bonus.id == bonusDeleteId"
                :depressed="true"
                color="error"
                class="mr-2 white--text lighten-1"
                @click="confirmDeleteBonusClick(bonus.id)"
              >
                Confirmar borrado
              </v-btn>
              <v-btn
                elevation="0"
                :depressed="true"
                v-if="deleteModeBonus && bonus.id == bonusDeleteId"
                color="warning white--text"
                class="mr-2"
                @click="cancelDeleteBonusClick"
              >
                Atrás
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.installment-value {
  width: 100%;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
// import RentInstallmentInput from "../../RentInstallmentInput.vue";
import { DRAFT_FIXED_BONUS_QQ_CONTRACT } from "../../../../managrx/constants";
import { roundToX } from "../../../../managrx/utils";
export default {
  name: "AbstractFixQuintals",
  components: {},
  props: {
    physicalHectares: Number,
    parsedDefaultDate: String,
    rentPaymentPlan: Array,
    rentFixedRows: Array,
    rentBonuses: Array,
    campaignStartDate: String,
    rentQqHa: Number,
    rentQqActivityPrice: Number,
    draftEngagementActivitySlices: Array,
  },
  computed: {
    bonuses() {
      return this.rentBonuses.map((el) => {
        return {
          ...el,
          rentBonusPercent: el?.rentBonusPercent * 100,
          bonusDate: el?.bonusDate.split("T")[0],
          activityId: el?.activityId?.toString(),
        };
      });
    },
    totalPlanned() {
      return this.rentPaymentPlan
        .map((el) => el.qqHa)
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    totalFixed() {
      return roundToX(
        (10 *
          this.rentFixedRows
            .map((el) => el.fixedTon)
            .reduce((partialSum, a) => partialSum + a, 0)) /
          10 /
          this.physicalHectares,
        2
      );
    },
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    paymentPlanQqHaSum() {
      return this.rentPaymentPlan
        ?.map((i) => i.qqHa)
        ?.reduce((a, b) => a + b, 0);
    },
    activeBonusActivityIds() {
      return this.bonuses.map((el) => el?.activityId);
    },
    activities() {
      return this.draftEngagementActivitySlices.map((el) => {
        return { name: el?.activityName, id: el?.activityId };
      });
    },
    selectableActivities() {
      // Return active draft engagement activities, that does not have any bonus yet, sort by name
      return this.activities
        .filter((el) => !this.activeBonusActivityIds.includes(el.id))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    saveNewBonus() {
      if (this.$refs.form3.validate()) {
        let payload = {
          ...this.newBonus,
          rentBonusPercent: this.newBonus?.rentBonusPercent / 100,
          bonusDate: new Date(this.newBonus?.bonusDate).toISOString(),
        };
        this.$emit("saveNewBonus", payload);
        this.newBonus = {
          activityId: null,
          yieldThreshold: null,
          bonusDate: new Date().toISOString().split("T")[0],
          rentBonusPercent: null,
        };
        this.$refs.form3.resetValidation();
      }
    },
    addKgBonuses() {
      this.$emit("addKgBonuses");
    },
    addUsdBonuses() {
      this.$emit("addUsdBonuses");
    },
    saveEditBonus(bonus) {
      this.bonusEditId = null;
      this.editBonusMode = false;
      let payload = {
        id: bonus?.id,
        bonus: {
          ...bonus,
          rentBonusPercent: bonus?.rentBonusPercent / 100,
          bonusDate: new Date(bonus?.bonusDate).toISOString(),
        },
      };
      this.$emit("saveEditBonus", payload);
    },
    cancelBonusEdit() {
      this.editBonusMode = false;
      this.bonusEditId = null;
    },
    editBonusClick(id) {
      this.editBonusMode = true;
      this.bonusEditId = id;
    },
    deleteBonusClick(id) {
      this.bonusDeleteId = id;
      this.deleteModeBonus = true;
    },
    confirmDeleteBonusClick(id) {
      this.$emit("deleteBonus", id);
    },
    cancelDeleteBonusClick() {
      this.deleteModeBonus = false;
      this.bonusDeleteId = null;
    },
    quickFixRent() {
      this.rentQqHa = this.paymentPlanQqHaSum;
      this.editedRentQqHa = this.rentQqHa;
      this.editedRentQqActivityPrice = this.rentQqActivityPrice;
      this.saveClick();
    },
    exitEditPlannedInstallment() {
      this.editModePlanned = false;
      this.plannedInstallmentEdit = { id: null };
    },
    editPlannedInstallmentClick(plannedInstallment) {
      this.editModePlanned = true;
      this.plannedInstallmentEdit = {
        ...plannedInstallment,
        date: plannedInstallment.date.split("T")[0],
      };
    },
    saveEditPlannedInstallment() {
      this.editModePlanned = false;
      let payload = {
        id: this.plannedInstallmentEdit.id,
        installment: {
          qqHa: this.plannedInstallmentEdit.qqHa,

          date: new Date(this.plannedInstallmentEdit.date).toISOString(),
        },
      };
      this.$emit("saveEditPlannedQuintals", payload);
    },
    exitDeletePlannedInstallment() {
      this.deleteModePlanned = false;
      this.plannedInstallmentDelete = { id: null };
    },
    deletePlannedInstallmentClick(plannedInstallment) {
      this.deleteModePlanned = true;
      this.plannedInstallmentDelete = {
        ...plannedInstallment,
        date: plannedInstallment.date.split("T")[0],
      };
    },
    confirmDeletePlannedInstallment() {
      this.$emit("deletePlannedQuintals", this.plannedInstallmentDelete?.id);
    },
    exitEditFixedInstallment() {
      this.editModeFixed = false;
      this.fixedInstallmentEdit = { id: null };
    },
    editFixedInstallmentClick(fixedInstallment) {
      this.editModeFixed = true;
      this.fixedInstallmentEdit = {
        ...fixedInstallment,
        date: fixedInstallment.date.split("T")[0],
      };
    },
    saveEditFixedInstallment() {
      this.editModeFixed = false;
      let payload = {
        id: this.fixedInstallmentEdit.id,
        installment: {
          fixedTon: this.fixedInstallmentEdit.fixedTon,
          priceTon: this.fixedInstallmentEdit.priceTon,
          date: new Date(this.fixedInstallmentEdit.date).toISOString(),
        },
      };
      this.$emit("saveEditFixedQuintals", payload);
    },
    exitDeleteFixedInstallment() {
      this.deleteModeFixed = false;
      this.fixedInstallmentDelete = { id: null };
    },
    deleteFixedInstallmentClick(fixedInstallment) {
      this.deleteModeFixed = true;
      this.fixedInstallmentDelete = {
        ...fixedInstallment,
        date: fixedInstallment.date.split("T")[0],
      };
    },
    confirmDeleteFixedInstallment() {
      this.$emit("deleteFixedQuintals", this.fixedInstallmentDelete?.id);
    },
    saveNewFixedInstallment() {
      if (this.$refs.form1.validate()) {
        this.$emit("saveNewFixedQuintals", {
          ...this.newFixedInstallment,
          date: new Date(this.newFixedInstallment?.date).toISOString(),
        });

        this.newFixedInstallment = {
          fixedTon: null,
          priceTon: null,
          date: new Date().toISOString().split("T")[0],
        };
        this.$refs.form1.resetValidation();
      }
    },
    saveNewPlannedInstallment() {
      if (this.$refs.form2.validate()) {
        this.$emit("saveNewPlannedQuintals", {
          ...this.newPlannedInstallment,
          date: new Date(this.newPlannedInstallment?.date).toISOString(),
        });
        this.newPlannedInstallment = {
          qqHa: null,
          date: new Date().toISOString().split("T")[0],
        };
        this.$refs.form2.resetValidation();
      }
    },
  },
  data() {
    return {
      bonusDeleteId: null,
      bonusEditId: null,
      deleteModeBonus: false,
      fixedInstallmentEdit: {
        id: null,
      },
      fixedInstallmentDelete: {
        id: null,
      },
      plannedInstallmentEdit: {
        id: null,
      },
      plannedInstallmentDelete: {
        id: null,
      },
      editBonusMode: false,
      editModePlanned: false,
      editModeFixed: false,
      deleteModePlanned: false,
      deleteModeFixed: false,
      rentEditMode: false,
      editedRentQqHa: null,
      bonusOptions: DRAFT_FIXED_BONUS_QQ_CONTRACT.contractOptions,
      newBonus: {
        activityId: null,
        yieldThreshold: null,
        bonusDate: this.parsedDefaultDate,
        rentBonusPercent: null,
      },
      newPlannedInstallment: {
        qqHa: null,
        date: this.parsedDefaultDate,
      },
      newFixedInstallment: {
        fixedTon: null,
        priceTon: null,
        date: this.parsedDefaultDate,
      },
    };
  },
};
</script>
